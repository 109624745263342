
import { stringRules } from '@/utils/formRules'
import { defineComponent, reactive, ref } from 'vue'

export default defineComponent({
  props: {
    costBy: {
      type: String,
      required: true,
    },
  },
  setup(props, context) {
    const state = reactive({
      attribute: {
        name: '',
        value: '',
        code: '',
        cost: 0,
      },
    })
    const rules = {
      name: stringRules('color name', 1, 128),
      // value: stringRules('attribute value', 2, 128),
    }

    const catalogAttribute = ref()
    const dialogVisible = ref(false)

    const onSubmit = () => {
      catalogAttribute.value?.validate((isValid: boolean) => {
        if (isValid) {
          context.emit('callbackAttribute', 'color', { ...state.attribute })
          close()
        }
      })
    }

    const toggle = () => {
      dialogVisible.value = !dialogVisible.value
    }

    const close = () => {
      catalogAttribute.value?.resetFields()
      dialogVisible.value = false
    }

    return {
      state,
      rules,
      catalogAttribute,
      dialogVisible,
      onSubmit,
      toggle,
      close,
    }
  },
})
